import React from "react";
import { Navigation } from "../components/global/nav";
import { Footer } from "../components/global/footer";
import { graphql } from "gatsby";
import "../styles.css"
import SEO from "../components/global/seo";
import Breadcrumbs from "../components/global/breadcrumbs";
import CookiePrompt from "../components/global/cookies-prompt";

const HelpAdvice = props => {
   const mans = props.data.allContentfulManufacturerLanding.edges;
   mans.sort((a, b) => a.node.manufacturer.localeCompare(b.node.manufacturer))

   const guides = props.data.allContentfulGuide.edges;

   console.log(mans)

   const createManufacturerList = () => {
      let jsx = [];

      for (let i = 0; i < mans.length; i++) {
         const el = mans[i];
         
         jsx.push(
            <a className="guide-category-entry" href={`/${el.node.slug}`}>{el.node.manufacturer}</a>
         )
      }

      return jsx;
   }

   const createGuidesList = () => {
      let jsx = [];

      for (let i = 0; i < guides.length; i++) {
         const el = guides[i];

         jsx.push(
            <a className="guide-category-entry" href={`/${el.node.slug}`}>{el.node.title}</a>
         )
         
      }

      return jsx;
   }

   return (
      <React.Fragment>
         <SEO
            title="Help & Advice"
            slug="guides"
            metadescription="Get help with your boiler using Smart Plan's helpful guides, either on a general basis or specific to your boiler manufacturer and model."
            image=""
            titleTemplate="%s - Smart Plan"
         />
         <CookiePrompt/>
         <Navigation/>
            <div className="standard-title-container default">
               <div className="standard-title-inner-container body-inner-width">
                  <div className="standard-title-inner-content">
                     <h1>Help & Advice</h1>
                     <p>Get help with your boiler using our helpful guides, either on a general basis or specific to your boiler manufacturer and model.</p>
                  </div>
               </div>
            </div>

            <div className="standard-container">
               <div className="standard-inner-container body-inner-width">
                  <div className="standard-content-container">
                     <div className="content-column">
                        <Breadcrumbs
                           location="guides"
                        />

                        <h2>Advice by manufacturer</h2>

                        {/* Create provider stream */}
                        {mans.map((manufacturer) => (
                           <a className="manufacturer-stream-card" href={`/${manufacturer.node.slug}`}>
                              <span>
                                 <h3>{manufacturer.node.manufacturer}</h3>
                                 <p>{manufacturer.node.metaDescription}</p>
                              </span>
                              
                              <div className="manufacturer-stream-card-right">
                                 <img src={`https:${manufacturer.node.companyLogo.file.url}`}/>
                                 <a href={`/${manufacturer.node.slug}`} className="large-button green-button">Go to guides</a>
                              </div>
                           </a>
                        ))}
                     </div>  

                     {/* SIDEBAR */}
                     <div className="navigation-column">
                        <div className="navigation-entries-container">
                           <div class="sidebar-cta-container">
                              <h3>Speak to an engineer</h3>
                              <p>Call Smart Plan and speak to our expert engineering team free of charge.</p>
                              <a className="sidebar-cta-btn phone" href="tel:+443337726247">0333&nbsp;772&nbsp;6247</a>
                           </div>
                        </div>
                     </div> 
                     
                  </div>
               </div>
            </div>
         <Footer/>
      </React.Fragment>
   )
}

export default HelpAdvice

export const pageQuery = graphql`
   query ContentfulManufacturers {
      allContentfulManufacturerLanding {
         edges {
            node {
               manufacturer
               slug
               companyLogo {
                  file {
                     url
                  }
               }
               metaDescription
            }
         }
      }

      allContentfulGuide {
         edges {
            node {
               title
               slug
            }
         }
      }
   }

`